import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

const SWrap = styled.div<{ hasError: boolean }>`
  position: relative;

  textarea {
    padding: 12px;
    width: 100%;
    height: 150px;
    color: #000;
    outline: none;
    resize: none;
    margin-top: 30px;
    border: ${({ hasError }) => hasError ? '2px solid #ff0033': '2px solid transparent'};
  }

  div.error {
    position: absolute;
    bottom: 0;
    margin-bottom: -15px;
    color: #ff0033;
    font-size: 12px;
  }
`;

type Props = {
  placeholder?: string;
  name: string;
};

const TextArea = ({ placeholder, name, ...otherProps }: Props) => {
  const [field, meta, helpers] = useField(name);
  return (
    <SWrap hasError={Boolean(meta.error && meta.touched)}>
      <textarea
        autoComplete="off"
        placeholder={placeholder}
        {...field}
        {...otherProps}
      />
      {meta.error && meta.touched && <div className="error">{meta.error}</div>}
    </SWrap>
  );
};

export default TextArea;
