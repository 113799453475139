import React, { useState } from 'react';
import styled from 'styled-components';
import UseAnimations from 'react-useanimations';
import linkedin from 'react-useanimations/lib/linkedin';
import github from 'react-useanimations/lib/github';
import twitter from 'react-useanimations/lib/twitter';
import youtube from 'react-useanimations/lib/youtube';

import logo from '../../images/logo.svg';
import ContactForm from './ContactForm';
import Button from '../Button';

const SWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  order: 1;
  flex-grow: 1;

  @media (max-width: 1200px) {
    order: 2;
  }
`;

const SLogo = styled.h1`
  align-self: center;
  margin: 0;
  padding-top: 40px;

  img {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 1200px) {
    padding-bottom: 15px;

    img {
      width: 70px;
      height: 70px;
    }
  }
`;

const SMiddle = styled.div`
  max-width: 600px;
  padding: 20px;

  @media (max-width: 1200px) {
    padding-bottom: 60px;
  }
`;

const SHeading = styled.h2`
  font-family: 'QuanticoBold', sans-serif;
  font-size: 52px;
  margin: 0;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 45px;
  }
`;

const SCaption = styled.h3`
  font-size: 16px;
  line-height: 1.4em;
  text-align: center;
`;

const SButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  > button {
    margin-right: 15px;
  }
`;

const SBottom = styled.div`
  display: flex;
  padding: 20px 20px 40px 20px;
  line-height: 1;

  a:not(:last-of-type) {
    margin-right: 15px;
  }

  @media (max-width: 1200px) {
    padding-top: 60px;
  }
`;

const HeaderContent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeForm = () => {
    setIsOpen(false);
  };

  const openForm = () => {
    setIsOpen(true);
  };

  return (
    <SWrap>
      <ContactForm isOpen={isOpen} close={closeForm} />
      <SLogo>
        <img src={logo} alt="Marek Feikus Logo" />
      </SLogo>
      <SMiddle>
        <SHeading>I write clean & scalable code</SHeading>
        <SCaption>
          Over a decade in software development — growing from a junior frontend
          dev into a Tech Lead role over the years.
        </SCaption>
        <SCaption>
          I’ve built fast, scalable web apps with React and Node.js — these
          days, using mostly the power of Next.js.
        </SCaption>
        <SCaption>
          Worked across startups, creative agencies, and corporate environments
          — I’ve led projects, mentored developers, and helped shape strong
          engineering teams.
        </SCaption>
        <SButtonWrap>
          <Button onClick={openForm}>CONTACT ME</Button>
        </SButtonWrap>
      </SMiddle>
      <SBottom>
        <a href="https://twitter.com/MarekFeikus" target="_blank">
          <UseAnimations strokeColor="#fff" animation={twitter} size={40} />
        </a>
        <a href="https://www.linkedin.com/in/marekfeikus" target="_blank">
          <UseAnimations strokeColor="#fff" animation={linkedin} size={40} />
        </a>
        <a href="https://github.com/marekfeikus" target="_blank">
          <UseAnimations strokeColor="#fff" animation={github} size={40} />
        </a>
        <a
          href="https://www.youtube.com/channel/UC8Dpzhj51eErty3vVROEdfg"
          target="_blank"
        >
          <UseAnimations strokeColor="#fff" animation={youtube} size={40} />
        </a>
      </SBottom>
    </SWrap>
  );
};

export default HeaderContent;
