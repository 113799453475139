import React from 'react';
import styled from 'styled-components';

import HeaderImage from './HeaderImage';
import HeaderContent from './HeaderContent';

const SWrap = styled.div`
  height: 100vh;
  display: flex;
  background: #000;
  overflow: hidden;

  @media (min-width: 1200px) and (max-height: 600px) {
    height: auto;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    height: auto;
  }
`;

function HeaderSection() {
  return (
    <SWrap>
      <HeaderContent />
    </SWrap>
  );
}

export default HeaderSection;
