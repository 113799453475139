import React from 'react';
import styled from 'styled-components';

const SButton = styled.button`
  padding: 15px;
  font-size: 16px;
  text-align: center;
  border: 2px solid #fff;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  transition: all 0.1s ease-in-out;
  margin-top: 30px;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover:not([disabled]) {
    color: #000;
    background-color: #fff;
  }
  &:focus {
    outline: none;
  }
`;

type Props = {
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

const Button = ({
  onClick,
  children,
  disabled = false,
  type = 'button',
  ...otherProps
}: Props) => {
  return (
    <SButton onClick={onClick} disabled={disabled} type={type} {...otherProps}>
      {children}
    </SButton>
  );
};

export default Button;
