import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

const SWrap = styled.div<{ hasError: boolean }>`
  position: relative;

  input {
    padding: 14px 12px;
    color: #000;
    outline: none;
    width: 100%;
    margin-top: 30px;
    border: ${({ hasError }) => hasError ? '2px solid #ff0033': '2px solid transparent'};
  }

  div.error {
    position: absolute;
    bottom: 0;
    margin-bottom: -15px;
    color: #ff0033;
    font-size: 12px;
  }
`;

type Props = {
  placeholder?: string;
  name: string;
};

const TextInput = ({ placeholder, name, ...otherProps }: Props) => {
  const [field, meta, helpers] = useField(name);
  return (
    <SWrap hasError={Boolean(meta.error && meta.touched)}>
      <input
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        {...field}
        {...otherProps}
      />
      {meta.error && meta.touched && <div className="error">{meta.error}</div>}
    </SWrap>
  );
};

export default TextInput;
