import React from 'react';
import 'normalize.css';

import GlobalStyle from '../styles/indexGlobal';
import HeaderSection from '../components/index/HeaderSection';
import SEO from '../components/Seo';

const IndexPage = () => (
  <>
    <SEO title="Marek Feikus | Fullstack JS Developer" />
    <GlobalStyle />
    <HeaderSection />
  </>
);

export default IndexPage;
