import { createGlobalStyle } from 'styled-components';

import QuanticoBold from '../fonts/Quantico-Bold.woff';
import RobotoMono from '../fonts/Roboto-Mono.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'QuanticoBold';
        src: local('QuanticoBold'),
        url(${QuanticoBold}) format('woff');
    }

    @font-face {
        font-family: 'RobotoMono';
        src: local('RobotoMono'), local('FontName'),
        url(${RobotoMono}) format('woff');
    }

    * {
        font-family: "RobotoMono", sans-serif;
        color: #fff;
        box-sizing: border-box;
    }

    body {
        background: #000;
    }
    body.ReactModal__Body--open {
        overflow: hidden;
    }
`;
