import _get from 'lodash/get';
import _omit from 'lodash/omit';
import axios from 'axios';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import ReCaptcha from 'react-recaptcha';
import * as Sentry from '@sentry/gatsby';

import background from '../../images/background_contact.svg';
import Button from '../Button';
import TextArea from '../TextArea';
import TextInput from '../TextInput';

const contactSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  subject: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Required'),
  message: Yup.string()
    .min(50, 'Too Short')
    .max(3000, 'Too Long')
    .required('Required'),
  token: Yup.string().required('Please verify that you are not a robot'),
});

const SForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
`;

const SWrap = styled.div`
  width: 500px;
  position: relative;
  padding: 0 0 40px 0;

  @media (max-width: 500px) {
    width: 300px;
  }
`;

const STop = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;

  span:last-child {
    cursor: pointer;
    padding-left: 11px;
    font-size: 26px;
  }
`;

const SButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const SButton = styled(Button)`
  margin: 0;

  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

const SReCaptchaWrapper = styled.div`
  position: relative;

  .reCaptchaError {
    position: absolute;
    bottom: 0;
    margin-bottom: -15px;
    color: #ff0033;
    font-size: 12px;
  }
`;

const Message = styled.div<{ submitState: SubmitState }>`
  color: ${({ submitState }) =>
    submitState === 'success' ? '#4BB543' : '#ff0033'};
  bottom: 0;
  position: absolute;
  text-align: center;
  width: 100%;
`;

type Props = {
  isOpen: boolean;
  close: () => void;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
  token: string;
};

type SubmitState = 'success' | 'error' | '';

const submitMessages: { [state in SubmitState]?: string } = {
  success: 'Thank you for contacting me.',
  error: 'Something went wrong, please try again later.',
};

let reCaptchaInstance: ReCaptcha | null;

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  message: '',
  token: '',
};

const sendFormDataAsync = async (values: Omit<FormValues, 'token'>) =>
  await axios.post('/.netlify/functions/sendEmail', values);

const ContactForm = ({ isOpen, close }: Props) => {
  const [submitState, setSubmitState] = useState<SubmitState>('');

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    try {
      await sendFormDataAsync(_omit(values, ['token']));
      resetForm();
      reCaptchaInstance?.reset();
      setSubmitState('success');
      document.querySelector('#form-error-message-div')?.scrollIntoView();
    } catch (err) {
      Sentry.captureException(err);
      setSubmitting(false);
      setSubmitState('error');
    }
  };

  return typeof window === 'undefined' ? null : (
    <Modal
      isOpen={isOpen}
      appElement={document.body}
      style={{
        overlay: {
          backgroundColor: 'transparent',
          borderRadius: 0,
        },
        content: {
          backgroundColor: '#000',
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'repeat',
          border: 'none',
          top: '0',
          right: '0',
          left: '0',
          bottom: '0',
          borderRadius: 0,
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={contactSchema}
      >
        {({
          isSubmitting,
          dirty,
          touched,
          errors,
          setFieldValue,
        }: FormikProps<FormValues>) => (
          <SForm>
            <SWrap>
              <STop>
                <span>CONTACT ME</span>
                <span
                  onClick={() => {
                    reCaptchaInstance?.reset();
                    close();
                  }}
                >
                  X
                </span>
              </STop>
              <TextInput name="firstName" placeholder="Name*" />
              <TextInput name="lastName" placeholder="Surname*" />
              <TextInput name="email" placeholder="Email*" />
              <TextInput name="subject" placeholder="Subject*" />
              <TextArea name="message" placeholder="Message*" />
              <SButtonSection>
                <SReCaptchaWrapper>
                  <ReCaptcha
                    ref={(e: any) => (reCaptchaInstance = e)}
                    sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                    render="explicit"
                    verifyCallback={(token: string) => {
                      setFieldValue('token', token);
                    }}
                    expiredCallback={() => {
                      setFieldValue('token', '');
                    }}
                    theme="dark"
                  />
                  {errors.token && touched.token && (
                    <p className="reCaptchaError">{errors.token}</p>
                  )}
                </SReCaptchaWrapper>
                <SButton
                  type="submit"
                  disabled={!dirty || isSubmitting || submitState === 'success'}
                >
                  SUBMIT
                </SButton>
              </SButtonSection>
              <Message submitState={submitState} id="form-error-message-div">
                {submitMessages[submitState]}
              </Message>
            </SWrap>
          </SForm>
        )}
      </Formik>
    </Modal>
  );
};

export default ContactForm;
